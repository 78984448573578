import { DatePicker, Button, Table, Modal, TimePicker ,Tag , Icon ,notification ,Avatar} from "antd";
import React, { useEffect, useState } from "react";
import Home from "../Home";
import axios from "axios";
import appConfig from "../../helpers/appConfig";
import moment from "moment";
import Layout from "../Home";
import { Row, Col, Collapse } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import Form from "react-bootstrap/Form";
import { record } from "react-icons-kit/iconic";
import { badge } from "react-icons-kit/iconic";

const { MonthPicker } = DatePicker;

const index = (props) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalDocs, setTotalDocs] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isShowConfirm, setIsShowConfirm] = useState(false);
  const [id, setId] = useState("");
  const [single, setSingle] = useState();
  const [isViewClicked, setIsViewClicked] = useState(false);

  const [reservedtabledata, setReservedTableData] = useState([]);
  const [tableNumber, setTableNumber] = useState(1);
  const [reservationTime, setReservationTime] = useState("11:00");

  const [dateRange, setDateRange] = useState();
  const [rangeType, setRangeType] = useState("d");
  const [totalofTax, setTotalOfTaxes] = useState("");
  const [reserveTableCount, setReserveTableCount] = useState(0);
  const [todayCount, setTodayCount] = useState(0);
  const today = moment().format('DD/MM/YYYY');

  console.log("eeeeeeeeeeeeee", totalofTax);

  useEffect(() => {
    getData();
  }, [page, pageSize, dateRange]);

  useEffect(() => {
    console.log("sinngeioefe", single);
  });
  useEffect(() => {
    let myReserveTableCount2 = localStorage.getItem("reserveTableCount");
    setReserveTableCount(myReserveTableCount2);
  }, [reserveTableCount]);

  useEffect(() => {
    let myTodayCount = localStorage.getItem("todayCount");
    setTodayCount(myTodayCount)
  }, [todayCount]);
  console.log("myTodayCount", todayCount);
  useEffect(() => {
    //setReservationTime();
  }, [reservedtabledata, reservationTime]);

  const handleFilter = (date, range) => {
    let editedDate = "";
    let type = range == "m" ? "m" : "d";
    if (date) {
      editedDate = new Date(date);
    }
    console.log(editedDate);
    setDateRange(editedDate);
    setRangeType(type);
  };

  const handleTimeChange = (time, timeString) => {
    console.log("---Time---", time, timeString);
    setReservationTime(timeString);
    console.log("---Changed time is:---", reservationTime);
  };

  ///Get info from data state, used to set time in timepicker
  const getLocalData = (id) => {
    let i = data.findIndex((item) => item._id === id);
    setReservationTime(data[i].time);
    console.log(data[i].time);
  };

  ///Get Reserved table details by reservation id
  const getInfo = async (reservation_id) => {
    console.log("--Selected Reservation id---", reservation_id);

    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/public/getreservedtable`,
      {
        reservationId: reservation_id,
      }
    );

    console.log("---Resevered Table Id---", res.data);

    if (res.status == 200) {
      setReservedTableData(res.data.data);
      //setReservationTime(res.data.data.time);
      console.log("---Time from server", reservationTime);
    } else {
    }
  };

  //Get all data
  const getData = async () => {
    let formData = {
      page: page,
      pageSize: pageSize,
    };

    if (dateRange && dateRange !== null && dateRange != "") {
      console.log(dateRange);
      formData = { ...formData, date: dateRange, rangeType: rangeType };
    }

    console.log("called");
    setIsLoading(true);

    const res = await axios.get(
      `${appConfig.endpoint}/api/v1/public/getallreservedtables`,
      {
        // params: {
        //   page: page,
        //   pageSize: pageSize
        // }
        params: formData,
      },
      {}
    );
    console.log("res", res);
    console.log("------res----data-----", res.data);
    if (res) {
      setData(res.data.data.docs);
      setTotalDocs(res.data.data.totalDocs);
      setIsLoading(false);
    }
  };

  const handleOk = async () => {
    console.log("called");

    let formData = {
      id: id,
      status: "CONFIRM",
      tableNumber: tableNumber,
      time: reservationTime,
    };

    console.log("----Formdata is ----", formData);

    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/public/confirmorcancelreservedtable`,
      formData
    );

    console.log("ressss", res);

    if (res.status === 200) {
      setIsViewClicked(false);

      await getData();
    } else {
      console.error(`Unexpected response status: ${res.status}`);
    }

    setIsShowConfirm(false);
  };

  const handleCancel = async () => {
    console.log("called");
    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/public/confirmorcancelreservedtable`,
      {
        id: id,
        status: "CANCEL",
      }
    );
    console.log("ressss", res);

    getData();
    setIsShowConfirm(false);
  };

  const handleClose = () => {
    setTableNumber("");
    //setReservationTime('11:00');
    setIsShowConfirm(false);
  };

  // const handleView = (record) => {
  //   console.log("record", record);
  // };

  const columns = [
    {
      title: "Table #",
      key: "table",
      render: (text, record) => {
        return <b>{record.tableNumber}</b>;
      },
    },
    {
      title: "Name",
      key: "firstName",
      render: (text, record) => {
        return <b>{record.firstName + " " + record.lastName}</b>;
      },
    },
    {
      title: "Phone",
      key: "phone",
      render: (text, record) => {
        return <b>{record.phoneNumber}</b>;
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      // render: (text, record) => (
      //   <div style={{ color:moment(record.date).format("DD/MM/YYYY") === today ? 'red' : 'black' }}>
      //     {moment(record.date).format("DD/MM/YYYY")}
      //   </div>
      // ),
      render: (text, record) => {
        if (moment(record.date).format("DD/MM/YYYY") === today) {
            return (
              <Tag className="highlighted-row">  {moment(record.date).format("DD/MM/YYYY")}</Tag>
            );
        } else {
            return  moment(record.date).format("DD/MM/YYYY")
        }
    },
    },
    {
      title: "Time",
      dataIndex: "time",
      render: (text, record) => {
        if (moment(record.date).format("DD/MM/YYYY") === today) {
            return (
              <Tag className="highlighted-row"> {record.time}</Tag>
            );
        } else {
            return record.time
        }
    },
      key: "time",
    },
    {
      title: "Status",
      dataIndex: "reserveStatus",
      key: "reserveStatus",
      render: (text, record) => {
        if (record.reserveStatus == "CONFIRMED") {
            return (
                <p
                    style={{
                        background: "green",
                        textAlign: "center",
                        borderRadius: 5,
                        color: "white",
                        padding:"2px 2px "
                    }}
                >
                    {record.reserveStatus}
                </p>
            );
        } else if (record.reserveStatus == "PENDING") {
            return (
                <p
                    style={{
                        //  background: "orange",
                        textAlign: "center",
                        borderRadius: 5,
                        color: "black",
                        opacity: ".65"
                    }}
                >
                    {record.reserveStatus}
                </p>
            );
        } else if (record.reserveStatus == "IN-PROCESS") {
            return (
                <p
                    style={{
                        background: "orange",
                        textAlign: "center",
                        borderRadius: 5,
                        color: "white"
                    }}
                >
                    {record.reserveStatus}
                </p>
            );
        }
        else if (record.reserveStatus == "CANCELLED") {
            return (
                <p
                    style={{
                        background: "red",
                        textAlign: "center",
                        borderRadius: 5,
                        color: "white"
                    }}
                >
                    {record.reserveStatus}
                </p>
            );
        } else {
            return record.reserveStatus;
        }
    }
      // render: (text, record) => {
      //   let badgeStyle = "secondary";

      //   switch (record.reserveStatus) {
      //     case "PENDING":
      //       badgeStyle = "warning";
      //       break;

      //     case "CONFIRMED":
      //       badgeStyle = "success";
      //       break;

      //     case "CANCELLED":
      //       badgeStyle = "light";
      //       break;

      //     default:
      //       badgeStyle = "warning";
      //   }

      //   return (
      //     // <Badge pill bg={badgeStyle}>
      //     //   {record.reserveStatus}
      //     // </Badge>
      //     <p
      //     bg={badgeStyle}
      //     style={{
      //         textAlign: "center",
      //         borderRadius: 5,
      //         color: "white"
      //     }}
      // >
      //    {record.reserveStatus}
      // </p>
      //   );
      // },
    },
    {
      title: "Adult(s)",
      dataIndex: "numberOfSeats",
      key: "numberOfSeats",
    },
    {
      title: "Children(s)",
      dataIndex: "numberOfSeatsChildren",
      key: "numberOfSeatsChildren",
    },
    {
      title: "With Items",
      // dataIndex: "numberOfSeatsChildren",
      key: "items",
      render: (text, record) => {
        if (record.cart_items.items.length > 0) {
            return (
              <Tag color="green">YES</Tag>
            );
        } else {
            return    <Tag color="red">NO</Tag>
        }
    }
      // render:(text,record) => {
      //   return <b>{record.cart_items.items.length}</b>;
      // }
    },
    {
      title: "Advance Payment",
      // dataIndex: "cart_items.items.finalAmount",
      render: (text, record) => {
            return (
              <p>Kr. {record.cart_items.finalAmount ? record.cart_items.finalAmount : 0}</p>
            );
    },
      key: "note",
    },
    {
      title: "Note",
      dataIndex: "note",
      key: "note",
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   render: (text, record) => {
    //     //console.log(text, "text", record);
    //     return (
    //       text.reserveStatus == "PENDING" && (
    //         <Button
    //           onClick={() => {
    //             setIsShowConfirm(true);
    //             setId(record._id);
    //             getLocalData(record._id);
    //             getInfo(record._id);
    //           }}
    //         >
    //           {/* <i className="fa fa-eye"></i> */}Confirm
    //         </Button>
    //       )
    //     );
    //   },
    // },
    {
      title: "View",
      dataIndex: "view",
      key: "action",
      className:"view-title",
      render: (text, record) => {
        return (
          <Button
          className="btn-ab"
            onClick={() => {
              console.log("Setting single item:", record._id);
              setSingle(record);
              setIsViewClicked(true);
            }}
          >
          <i className="fa fa-eye" style={{color:"#dcbb68"}}></i>
          </Button>
        );
      },
    },
  ];


   ///Get Reserved table details by reservation id
   const sendInKitchenBar = async (reservation_id) => {
    console.log("--Selected Reservation id---", reservation_id);
    const token = localStorage.getItem("token");
    const headers = {
      "x-auth-token": token,
    };

    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/public/sendtokitchenbar`,
      {
        id: reservation_id,
      },
      {
        headers: headers,
      }
    );

    console.log("---Resevered Table Id---", res.data);

    if (res.status == 200) {
      notification.open({
        message: "Order send to kitchenbar.",
        icon: <Icon type="check-circle" style={{ color: "green" }} />,
        duration: 2,
      });
      setIsViewClicked(false)
      getData();
    } else {
      notification.open({
        message: "Order not found or it has not been confirmed yet.",
        icon: <Icon type="close-circle" style={{ color: "red" }} />,
        duration: 2,
      });
    }
  };
  function reserveTableItems() {
    const storedAddress = localStorage.getItem("branchInfo");
    const branchInfo = JSON.parse(storedAddress);
    const { name, address, taxNo, phoneNo, bEmail, accountNumber, orderType } =
      branchInfo;
    return (
      <>
        <div className="d-flex align-items-end justify-content-end">
          <Button
            type="primary"
            className="goBackButton"
            onClick={() => setIsViewClicked(false)}
          >
            Go Back
          </Button>
        </div>
        {/* <h3 style={{ fontWeight: "600", paddingTop: "20px" }}>
          Reserve Table Information{" "}
        </h3> */}
        <div
          className="d-flex align-items-center justify-content-between pt-4 "
          style={{ paddingBottom: "10px"}}
        >
          <div className="col p-0">
            {/* <p>{address}</p> */}

            <p style={{ fontWeight: "700" }}>
              {" "}
              <span style={{ fontWeight: "800" , fontSize:"12px"}}>Name:</span>{" "}
              {`${single.firstName} ${single.lastName}`}
            </p>
            <p>
              {" "}
              <span style={{ fontWeight: "800" , fontSize:"12px"}}>Telefon Nr.</span>
              {single.phoneNumber}
            </p>
            <p>
              {" "}
              <span style={{ fontWeight: "800", fontSize:"12px" }}>E-Post:</span> {single.email}
              {""}
            </p>
            <p>
              <span style={{ fontWeight: "800" , fontSize:"12px"}}>Children(s):</span>{" "}
              {single.numberOfSeatsChildren}
            </p>
            <p>
              <span style={{ fontWeight: "800" , fontSize:"12px"}}>Adult(s): </span>{" "}
              {single.numberOfSeats}
            </p>
            <p>
            {single.reserveStatus === "CONFIRMED" && single.billNumber == 0 &&
              single.cart_items.items.length > 0 
              &&  moment(single.date).format("DD/MM/YYYY") == today
              && (
              
                <Button
                  // type="primary"
                  className="confirmButton"
                  onClick={() => {
                    sendInKitchenBar(single._id)
                  }}
                >
                  Process
                </Button>
              )}
              {single.reserveStatus === "CONFIRMED" && single.billNumber > 0 && 
                single.cart_items.items.length > 0 && (
                <Tag color="red">Processed to Daily Bills</Tag>
              )}
              {single.reserveStatus === "PENDING" && (
                <Button
                  type="primary"
                  className="confirmButton"
                  onClick={() => {
                    setIsShowConfirm(true);
                    setId(single._id);
                    getLocalData(single._id);
                    getInfo(single._id);
                  }}
                >
                  Confirm
                </Button>
              )}
            </p>
          </div>
          <div className="col d-flex align-items-end  flex-column">
            <p>
              <span style={{ fontWeight: "800" , fontSize:"12px"}}>Date:</span>{" "}
              {moment(single.date).format("DD/MM/YYYY")}
            </p>
            <p>
              <span style={{ fontWeight: "800" , fontSize:"12px"}}>Time:</span> {single.time}
            </p>
            <p>
              <span style={{ fontWeight: "800" , fontSize:"12px"}}>Table#:</span>{" "}
              {single.tableNumber}
            </p>
            <p>
              {" "}
              <span style={{ fontWeight: "800" ,fontSize:"12px"}}>Status:</span>{" "}
              <Badge
                pill
                style={{fontSize:"10px" }}
                className="badge-status"
                bg={
                  single.reserveStatus === "PENDING"
                    ? "warning"
                    : single.reserveStatus === "CONFIRMED"
                    ? "success"
                    : single.reserveStatus === "CANCELLED"
                    ? "light"
                    : "warning"
                }
              >
                {single.reserveStatus}
              </Badge>
            </p>
            <p style={{color:"red"}}>
              <span style={{ fontWeight: "800" , fontSize:"12px" , color:"red"}}>Note: </span> {single.note}
            </p>
          </div>
        </div>
        <table
          className="reservation-table"
          style={{ borderCollapse: "collapse", width: "100%" }}
        >
          <thead>
            <tr>
              <th
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                  backgroundColor: "#f2f2f2",
                }}
              >
                Item Name
              </th>
              <th
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                  backgroundColor: "#f2f2f2",
                }}
              >
                Item Quantity
              </th>
              <th
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                  backgroundColor: "#f2f2f2",
                }}
              >
                Item Price
              </th>

              <th
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                  backgroundColor: "#f2f2f2",
                }}
              >
                Total Amount
              </th>
            </tr>
          </thead>
          <tbody>
            {single &&
            single.cart_items &&
            single.cart_items.items &&
            single.cart_items.items.length > 0 ? (
              single.cart_items.items.map((item, index) => (
                <tr key={index}>
                  {console.log("itemQuantity------", item.quantity)}

                  <td
                    style={{
                      border: "1px solid #dddddd",
                      textAlign: "left",
                      fontWeight: "500",
                      borderBottom: "1px solid #dddddd",
                      padding: "10px 8px",
                    }}
                  >
                    {item.name ? item.name : " No items in the cart"}
                  </td>
                  <td
                    style={{
                      border: "1px solid #dddddd",
                      textAlign: "left",
                      fontWeight: "500",
                      borderBottom: "1px solid #dddddd",
                      padding: "10px 8px",
                    }}
                  >
                    x{item.quantity ? item.quantity : "No items in the cart"}
                  </td>
                  <td
                    style={{
                      border: "1px solid #dddddd",
                      textAlign: "left",
                      fontWeight: "500",
                      borderBottom: "1px solid #dddddd",
                      padding: "10px 8px",
                    }}
                  >
                    kr.
                    {item.price
                      ? item.price
                      : " No items in the cart"}
                  </td>
                  <td
                    style={{
                      border: "1px solid #dddddd",
                      textAlign: "left",
                      borderBottom: "1px solid #dddddd",
                      padding: "10px 8px",
                    }}
                  >
                    Kr.{item.price ? item.price * item.quantity : " No items in the cart"}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="4"
                  style={{ textAlign: "center", fontSize: "30px" }}
                >
                  <h3> No items in the cart</h3>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="d-flex justify-content-end align-items-end flex-column pt-5">
          {console.log("single item", single)}
          <p className="mobileview-P d-flex">
            <span style={{ fontWeight: "700" }}>Sub-Totalt:</span>{" "}
            {/* Assuming this code is within the render method of a React component */}
            {single.cart_items &&
            single.cart_items.items &&
            single.cart_items.items.length > 0 ? (
              <div>
                <div>
                  :{" "}
                  {single.cart_items.subTotal}
                  {/* {single.cart_items.items.reduce(
                    (accumulator, item) => accumulator + (item.finalPrice || 0),
                    0
                  )} */}
                </div>
              </div>
            ) : (
              <div>{0}</div>
            )}
          </p>
          <p className="mobileview-P d-flex">
            <span style={{ fontWeight: 700 }}>Tax : </span>{" "}
            {single.cart_items &&
            single.cart_items.items &&
            single.cart_items.items.length > 0 ? (
              <div>
                <div>
                  :{" "}
                  {single.cart_items.tax}
                  {/* {single.cart_items.items.reduce(
                    (accumulator, item) => accumulator + (item.tax || 0),
                    0
                  )} */}
                </div>
              </div>
            ) : (
              <div>{0}</div>
            )}
          </p>

          <p className="mobileview-P d-flex">
            <span style={{ fontWeight: 700 }}>Totalt:</span>{" "}
            {single.cart_items.finalAmount ? Number(single.cart_items.finalAmount).toFixed(2) : 0.00}
            {/* {single.cart_items &&
            single.cart_items.items &&
            single.cart_items.items.length > 0 ? (
              <div>
                <div>
                  :{" "}
                  {single.cart_items.items.reduce(
                    (accumulator, item) => accumulator + (item.finaAmount || 0),
                    0
                  )}
                </div>
              </div>
            ) : (
              <div>{0}</div>
            )} */}
          </p>
         
        </div>
          <div className="d-flex justify-content-end align-items-end flex-column pt-2">
            {single.transaction && single.transaction.card ? (
              <>
                <Col align="right" className="row-font">
                  <Row align="text-center">
                    <Col>
                      <h4 style={{ fontWeight: "900" }}>Card Details</h4>
                    </Col>
                  </Row>
                  <p style={{ marginBottom: 4 }}>
                    <span style={{ fontWeight: "700" }}> Card:</span>
                    {single.transaction.card.masked_pan}
                  </p>
                  <p style={{ marginBottom: 4 }}>
                    <span style={{ fontWeight: "700" }}> Card Type: </span>
                    {single.transaction.card.brand}
                  </p>
                  <p style={{ marginBottom: 4 }}>
                    <span style={{ fontWeight: "700" }}> Expiry Date:</span>{" "}
                    {single.transaction.card.expiry_date}
                  </p>
                </Col>
              </>
            ) : (
              ""
            )}
          </div>
      </>
    );
  }

  return (
    <Home className="p-5">
      <Row className="report-container">
        <span className="item">
          <span id="less-visible">Opprett konto / </span>
          Reserve Table
        </span>
       
      </Row>
      <div className="marquee">
      <p>Restaurant be ready 😄! 
      Here are the details of Today's order count 💁 
      {/* <Tag color="#f50">{reserveTableCount}</Tag>  */}
        {/* <Avatar className="m-2" style={{ backgroundColor: '#07182c', color: '#b59b56',
        fontWeight:"800"
       // border:"1px solid #07182c" 
        }} size={20} >
        <span style={{fontSize:"11px"}}>{reserveTableCount}</span>
        </Avatar> */}
        <Badge className="m-2" pill bg="warning" text="dark">
        <span style={{fontWeight:"700" , fontSize:"12px"}}> {reserveTableCount}+</span>
      </Badge>
      number of orders are pending for confirmation and kindly note there are
      {/* <Avatar className="m-2"
      style={{ color: '#07182c', backgroundColor: '#b59b56' }} size={20} >
      <span style={{fontSize:"11px"}}>{todayCount}</span> 
      </Avatar> */}
      <Badge className="m-2" pill bg="warning" text="dark">
      <span style={{fontWeight:"700" , fontSize:"12px"}}> {todayCount}+</span>
      
      </Badge>
     
       number of today's booking.</p>
    </div>
      

      {!isViewClicked ? (
        <>
          <Col className="pb-3 justify-content-end">
            <DatePicker
              className="m-2"
              onChange={(e) => handleFilter(e, "d")}
            />
            <MonthPicker
              placeholder="Select Month"
              onChange={(e) => handleFilter(e, "m")}
            />
          </Col>
     

          <Row>
            <Table
              bordered
              // rowClassName={(record) => (moment(record.date).format("DD/MM/YYYY") === today ? 'highlight-row' : '')}
              loading={isLoading}
              dataSource={data}
              columns={columns}
              pagination={{
                total: totalDocs,
                current: page,
                pageSize: pageSize,
                onChange: (page, pageSize) => {
                  setPage(page);
                  setPageSize(pageSize);
                },
              }}
            />
          </Row>
        </>
      ) : (
        <>{reserveTableItems()}</>
      )}

      {isShowConfirm && (
        <Modal
          visible={isShowConfirm}
          title="Confirm Reservation"
          onOk={handleOk}
          centered
          onCancel={handleClose}
          footer={[
            <Button key="confirm" type="primary" onClick={handleOk}>
              Confirm
            </Button>,
            <Button key="submit" onClick={handleCancel}>
              Cancel Booking
            </Button>,
            <Button key="back" onClick={handleClose}>
              Close
            </Button>,
          ]}
        >
          <Row className="mb-3">
            <Col xs={6}>Customer Name</Col>
            <Col xs={6}>
              {reservedtabledata.firstName} {reservedtabledata.lastName}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={6}>Contact</Col>
            <Col xs={6}>{reservedtabledata.phoneNumber}</Col>
          </Row>
          <Row className="mb-3">
            <Col span={12}>Total Person(s)</Col>
            <Col span={12}>
              {Number(reservedtabledata.numberOfSeats) +
                Number(reservedtabledata.numberOfSeatsChildren)}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col span={12}>Timing ({reservedtabledata.time})</Col>
            <Col span={12}>
              {reservedtabledata.time != "" && (
                <TimePicker
                  format="HH:mm"
                  defaultValue={moment(reservationTime, "HH:mm")}
                  onChange={handleTimeChange}
                />
              )}
            </Col>
          </Row>

          <Row className="mb-3">
            <Col span={12}>Table #</Col>
            <Col span={12}>
              <Form.Control
                size="sm"
                type="number"
                value={tableNumber}
                onChange={(txt) => setTableNumber(txt.target.value)}
              />
            </Col>
          </Row>
        </Modal>
      )}
    </Home>
  );
};

export default index;
