import axios from "axios";
import {
  GET_SUPER_CATEGORY,
  GET_CATEGORY,
  SUCCESS_MESSAGE,
  ERROR_MASSAGE,
  GET_ICONS,
} from "./types";
import appConfig from "../helpers/appConfig";
import { isServerLogout } from "../utils/isServerLogout";
export const getSupperCategory = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `${appConfig.endpoint}/api/v1/employee/menu/supercat`
    );
    dispatch({ type: GET_SUPER_CATEGORY, payload: res.data.data });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const getCategory = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `${appConfig.endpoint}/api/v1/employee/menu/category`
    );
    dispatch({ type: GET_CATEGORY, payload: res.data.data });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};

export const productData = () => async (dispatch) => {
  try {
    // const res = await axios.get(
    //   `${appConfig.endpoint}/api/v1/employee/menu/category`
    // );
    const res = await axios.get(
      `${appConfig.endpoint}/api/v1/employee/menu/productData`
    );
    dispatch({ type: GET_CATEGORY, payload: res.data.data });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};

export const addCategory = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/employee/menu/addCategory`,
      data
    );
    dispatch({
      type: SUCCESS_MESSAGE,
      payload: { ...res.data, actionType: "addCategory" },
    });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const getIcons = () => async (dispatch) => {
  const res = await axios.get(
    `${appConfig.endpoint}/api/v1/employee/menu/getIcons`
  );
  dispatch({ type: GET_ICONS, payload: res.data });
};
export const updateIcon = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/employee/menu/addIcon`,
      data
    );
    dispatch({
      type: SUCCESS_MESSAGE,
      payload: { ...res.data, actionType: "updateIcon" },
    });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const showWeb = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/employee/menu/isShowWeb`,
      data
    );
    dispatch({
      type: SUCCESS_MESSAGE,
      payload: res.data,
    });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const showExtra = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/employee/menu/isShowExtra`,
      data
    );
    dispatch({
      type: SUCCESS_MESSAGE,
      payload: res.data,
    });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const updateCategory = (data) => async (dispatch) => {
  try {
    const res = await axios.put(
      `${appConfig.endpoint}/api/v1/employee/menu/category`,
      data
    );
    dispatch({
      type: SUCCESS_MESSAGE,
      payload: res.data,
    });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const deleteCategory = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(
      `${appConfig.endpoint}/api/v1/employee/menu/category/${id}`
    );
    dispatch({
      type: SUCCESS_MESSAGE,
      payload: res.data,
    });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
